

@font-face {
	font-family: 'Playfair';
	src: url('~static/fonts/Playfair.ttf') format('truetype');
}

@font-face {
	font-family: 'CaslonGrad';
	src: url('~static/fonts/CaslonGrad.ttf') format('truetype');
}

@font-face {
	font-family: 'RidleyGrotesk';
	src: url('~static/fonts/RidleyGrotesk-Medium.eot');
	src: url('~static/fonts/RidleyGrotesk-Medium.woff') format('woff'), url('~static/fonts/RidleyGrotesk-Medium.ttf') format('truetype'), url('~static/fonts/RidleyGrotesk-Medium.svg') format('svg');
	font-weight: bold;
	// font-style: normal;
}

@font-face {
	font-family: 'RidleyGrotesk';
	src: url('~static/fonts/RidleyGrotesk-Medium.eot');
	src: url('~static/fonts/RidleyGrotesk-Medium.woff') format('woff'), url('~static/fonts/RidleyGrotesk-Medium.ttf') format('truetype'), url('~static/fonts/RidleyGrotesk-Medium.svg') format('svg');
	font-weight: bolder;
	// font-style: normal;
}


@font-face {
	font-family: 'RidleyGrotesk';
	src: url('~static/fonts/RidleyGrotesk-Regular.eot');
	src: url('~static/fonts/RidleyGrotesk-Regular.woff') format('woff'), url('~static/fonts/RidleyGrotesk-Regular.ttf') format('truetype'), url('~static/fonts/RidleyGrotesk-Regular.svg') format('svg');
	// font-weight: normal;
	// font-style: normal;
}

$font-size-base: 0.9rem;
// $pagination-padding-x: .15rem;


// $line-height-lg: 0.8;
// $line-height-sm: 0.5;
$card-spacer-y: .25rem;
// $card-spacer-x: .15rem;


$background_color: #FFFFFF;
$background_color_shade1: #CCCCCC;

$font_color: #000000;
$font_family: 'RidleyGrotesk';

$blue_h8: #00356b;
$custom_light: #BBB;
$enable-rounded: false;

$theme-colors: (
	// "secondary": #00356b,
	"blue-h8": #00356b,
	"custom-light": $custom_light,
);

$custom-control-indicator-size: 2rem;
$custom-control-indicator-border-width: 1px;
$custom-switch-width: $custom-control-indicator-size * 1.75;

