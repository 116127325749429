.cti-container {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index:2000;

  .cti-notif {
    background-color: #00356b;
    color: white;
    padding: 25px;
    border-radius: 15px;
  }
}