.confirmation-list {
  .pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;

  }

  div.actions {
    display: flex;
    justify-content: space-evenly;
  }
}