@import "static/css/collapse.scss";

.user-details-container {
  .card-header {
    cursor: pointer;
  }

  @include  collapse(1200px, "-user");

  .actions {
    button {
      margin-right: 5px;
    }
  }

}