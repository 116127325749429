// @import "~app/components/common/icons";

.alert-banner {
  position: fixed;
  top: 0px;
  right: 0px;
  // width: 0px;
  // width: 320px;
  // height: 100vh;
  padding: 20px;
  z-index: 10000;
  // overflow: hidden;
  transition: opacity 0s 1500ms, opacity 1500ms ease-in-out, all 1500ms ease-out;
  // -webkit-transition: visibility 0s 1500ms, opacity 1500ms ease-in-out, all 1500ms ease-out;
  // -moz-transition: visibility 0s 1500ms, opacity 1500ms ease-in-out, all 1500ms ease-out;
  // -ms-transition: visibility 0s 1500ms, opacity 1500ms ease-in-out, all 1500ms ease-out;
  // -o-transition: visibility 0s 1500ms, opacity 1500ms ease-in-out, all 1500ms ease-out;

  .progress-container {
    padding: 0;

    .progress {
      height: 2px;
    }
  }

  &.hidden {
    visibility: hidden;
    display: none;
  }

  .spinner-container {
    position: absolute;
    top: 40px;
    right: 30px;
  }

  .alert-item {
    border-style: solid;
    border-width: 1px;
    border-radius: 10px;
    margin-top: 10px;
    min-height: 120px;
    padding: 15px;
    z-index: 5000;
    background-color : #FFFFFF;
    // border-color: #dc1b28;
    color: #3c763d;
    width: 320px;
    transition: visibility 0s 1500ms, opacity 1500ms ease-in-out, all 1500ms ease-out;

    &.message {
      background-color: #555555 !important;
      border-color: #6c757d;
      color: white;

      .progress {
        background-color: #191616;
      }
      .progress-bar {
        background-color: white;
      }
    }

    &.error {
      background-color: #dc1b28;
      color: white;
      border-color: #dc1b28;

      .progress {
        background-color: #dc1b28;
      }
      .progress-bar {
        background-color: white;
      }
    }

    .content {
      min-height: 90px;
      color: white;
    }

    &.alert-hidden {
      opacity: 0;
    }
  }
}

.alert-container {
  display: flex;
}
