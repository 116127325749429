.hotel_container {
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
  min-height: 100vh;


  h1 {
    color: var(--theme-color);
    border-bottom: 0;
    text-transform: uppercase;
    font-family: "Playfair";
    font-size: 1.6rem;
  }

  h2 {
    font-size: 1.2rem;
    font-family: "Playfair";
  }

  .filter {
    // position: relative;
    // background-color: rgba(255, 255, 255, 0.4);
    height: 100%;
    min-height: 100vh;
    width: 100vw;

    .container {
      padding-bottom: 150px;

      .hotel_container_children {
        padding-bottom: 30px;

        .header {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          // padding: 30px;

          img {
            width: 250px;
            // max-height: 250px;
          }
        }
      }
    }
  }
}
