$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";

@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";

.icon-warning-sign {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-exclamation-circle;
  color: red;
  font-size: 20px;
}

.icon-pending {
  @extend %fa-icon;
  @extend .far;
  @extend .fa-hourglass;
  font-size: 16px;
}

.icon-warning {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-exclamation-triangle;
  color: orange;
  font-size: 20px;
}

.icon-globe {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-globe;
  font-size: 20px;
  color: rgba(0,0,0,0.5)
}

.icon-flag {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-flag;
  font-size: 20px;
  color: #ffc107;
}

.icon-man {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-male;
  font-size: 20px;
  color: rgba(0,0,0,0.5)
}

.icon-woman {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-female;
  font-size: 20px;
  color: rgba(0,0,0,0.5)
}

.icon-calendar {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-calendar-day;
  font-size: 20px;
}

.icon-book-open {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-book-open;
  font-size: 20px;
}

.icon-home {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-home;
  font-size: 20px;
}

.icon-address-card {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-address-card;
  font-size: 20px;
}

.icon-user-friends {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-user-friends;
  font-size: 20px;
}

.icon-user {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-user;
  font-size: 20px;
}

.icon-phone {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-phone;
  font-size: 20px;
}

.icon-check {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-check-circle;
  color: green;
  font-size: 20px;
}

.icon-notch {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-circle-notch;
  @extend .fa-spin;
}

.icon-bars {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-bars;
}

.icon-play {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-play;
  // @extend .fa-spin;
}

.icon-stop {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-stop;
  // @extend .fa-spin;
}

.icon-arrow-up {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-arrow-up;
}

.icon-arrow-down {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-arrow-down;
}

.icon-chevron-left {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-chevron-left;
}

.icon-chevron-right {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-chevron-right;
}

.icon-spinner {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-spinner;
  @extend .fa-spin;
}

.icon-save {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-save;
}

.icon-loading {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-spinner;
  @extend .fa-spin;
}

.icon-circle-plus {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-plus-circle;
}

.icon-circle {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-circle;
}

.icon-arrow {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-arrow-circle-right;
}

.icon-trash {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-trash;
}

.icon-search {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-search;
}

.icon-more {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-info-circle;
}

.icon-enveloppe {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-envelope;
}

.icon-phone {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-phone;
}

.icon-comment {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-comment;
}

.icon-apple {
  @extend %fa-icon;
  @extend .fab;
  @extend .fa-apple;
}

.icon-android {
  @extend %fa-icon;
  @extend .fab;
  @extend .fa-android;
}

.icon-linkedin {
  @extend %fa-icon;
  @extend .fab;
  @extend .fa-linkedin;
}

.icon-ellipsis {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-ellipsis-h;
}

.icon-wifi {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-wifi;
}

.icon-random {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-random;
}

.icon-cloud {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-cloud;
}

.icon-close {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-times-circle;
}

.icon-lock {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-lock;
  color: #555;
}

.icon-info {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-info-circle;
  // color: #555;
}

.icon-close-naked {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-times;
}

.icon-envelope {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-envelope;
}

.icon-edit {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-edit;
}

.icon-rewind {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-history;
}

.icon-star-full {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-star;
}

.icon-star-shallow {
  @extend %fa-icon;
  @extend .far;
  @extend .fa-star;
}
