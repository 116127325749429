.content-modal-test {
  h1 {
    color: var(--theme-color);
    border-bottom: 0;
    text-transform: uppercase;
    font-family: "Playfair";
    font-size: 1.6rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: var(--theme-color);
    background-color: var(--theme-color);
  }

  .btn:not(.external) {
    color: #fff;
    background-color: var(--theme-color) !important;
    border-color: var(--theme-color) !important;
  }

  .form-control {
    border: 1px solid var(--theme-color);
  }

  .input-group-text {
    background-color: var(--theme-color);
    border: 1px solid var(--theme-color);
    color: white;
  }
  
}


.post-stay-container {
  text-align: center;
  font-family: "Playfair";
  padding: 5px;

  .row {
    margin-left: 0;
    margin-right: 0;
  }


  .alert-message {
    font-size: 1.3em;
    background-color: var(--theme-color);
    color: white;
  }


  h1 {
    color: var(--theme-color);
    border-bottom: 0;
    text-transform: uppercase;
    font-family: "Playfair";
    font-size: 1.6rem;
    padding-bottom: 10px;
  }

  h2 {
    font-size: 1.2rem;
    font-family: "Playfair";
  }


  h3 {
    font-size: 1.0rem;
    font-family: "Playfair";
  }

  .separator {
    h1 {
      // padding-left: 30px;
      justify-content: start;
      align-items: start;
      text-align: left;
      font-size: 1.4rem;
      padding-left:  0;
      padding-top: 20px;
      padding-bottom: 1px;
    }
  }

  .icon-star-full {
    // background-color: var(--theme-color);
    color: var(--theme-color);
  }

  .icon-star-shallow {
    // background-color: var(--theme-color);
    color: var(--theme-color);
  }


  .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: var(--theme-color);
    background-color: var(--theme-color);
  }

  .btn:not(.external) {
    color: #fff;
    background-color: var(--theme-color) !important;
    border-color: var(--theme-color) !important;
  }

  .form-control {
    border: 1px solid var(--theme-color);
  }

  input[type="radio"] {
    display: none;
    // visibility: hidden
  }


  label.checked::after {
    background-color: var(--theme-color) !important;
  }

  label {
    display: flex;
    // // flex-wrap: nowrap;
    // flex-shrink: 0;
    // justify-content: center;
    align-items: center;
    flex-direction: column;

  }

  label::after {
    content: "";
    align-items: center;
    // display: inline-block;
    justify-content: center;
    text-align: center;
    display: flex;
    width: 16px;
    height: 16px;
    // margin-right: 8px;
    border: 1px solid #aaa;
    /* Couleur de la bordure */
    border-radius: 50%;
    background-color: white;
    /* Couleur de fond */
    // vertical-align: middle;
    // align-items: center;


  }

  // input[type="radio"]:checked::before {
  //   content: "";
  //   display: inline-block;
  //   width: 16px;
  //   height: 16px;
  //   margin-right: 8px;
  //   border: 1px solid #aaa;
  //   /* Couleur de la bordure */
  //   border-radius: 50%;
  //   background-color: var(--theme-color) !important;
  //   /* Couleur de fond */
  //   vertical-align: middle;
  //   align-items: center;


  //   // :checked {
  //   //   background-color: white !important;
  //   // }
  // }


  .input-group-text {
    background-color: var(--theme-color);
    border: 1px solid var(--theme-color);
    color: white;
  }

  .horizontal-separator {
    border-right: 2px var(--theme-color) solid;
    padding-right: 85px !important;
  }

  .title-map-container {
    // .focused {
    //   // border: 5px red solid;
    // }

    .title {
      transition: ease all 500ms;
      transition-duration: 0.5s;
      padding-top: 15px;

      h3 {
        transition: ease all 500ms;
        padding: 0;
        margin: 0;
        font-size: 1.8rem;
        text-align: left;
        color: var(--theme-color);
        font-family: "CaslonGrad";
        text-transform: uppercase;
      }

      h2 {
        transition: ease all 500ms;
        padding: 0;
        margin: 0;
        font-size: 1.0rem;
        text-align: left;
        color: var(--theme-color);
        font-family: "CaslonGrad";
        text-transform: uppercase;
      }

      &.focused {
        h3 {
          transform-origin: top left;
          transform: scale(1.4, 1.4) translateY(-5px);
          // font-size: 4rem;
          font-weight: bold;
        }

        h2 {
          transform-origin: top left;
          transform: scale(1.4, 1.4);
          font-weight: bold;
          // font-size: 1.8rem;
        }
      }
    }
  }
}