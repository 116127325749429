@import "static/css/collapse.scss";

.contact-list-edit-container {
  .actions {
    .btn {
      margin-right: 10px;
    }
  }

  @include collapse(1800px, "");

}