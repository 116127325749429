.proposition-container {

  .actions {
    display: flex;
    flex-direction: row;
    // justify-content: space-around;
    // align-items: flex-start;

    button {
      margin-right: 5px;
    }
  }

}


.results-container {

  .category-label {
    min-width: 80px;
  }

  .category-group {
    height: 100%;

    .row {
      min-height: 40px;

      .choice {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}