// @import "~app/components/common/icons";
// @import "~bootstrap/scss/bootstrap";

@import "./theme.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
// @import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
// @import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
// @import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
// @import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
// @import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
// @import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";
@import "~bootstrap/scss/card";

// @import "./mdb.scss";

// // @import "./collapse.scss";


// $input-btn-padding-y:         .375rem !default;
// $input-btn-padding-x:         .75rem !default;

// $line-height-lg:              1.0 !default;
// $line-height-sm:              1.5 !default;


@mixin fontFamily {
  font-family: $font_family;
}

.container-full {
  padding: 0 30px;
}

// @font-face {
//   font-family: "Lato";
//   font-style: normal;
//   font-weight: 300;
//   font-display: swap;
//   src: local("Lato Light"), local("Lato-Light"), url(~static/fonts/S6u9w4BMUTPHh7USSwaPGQ3q5d0N7w.woff2) format("woff2");
//   unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
// /* latin */
// @font-face {
//   font-family: "Lato";
//   font-style: normal;
//   font-weight: 300;
//   font-display: swap;
//   src: local("Lato Light"), local("Lato-Light"), url(~static/fonts/S6u9w4BMUTPHh7USSwiPGQ3q5d0.woff2) format("woff2");
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
//     U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }

// /* latin-ext */
// @font-face {
//   font-family: "Lato";
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap; //     url(~app/static/fonts/S6uyw4BMUTPHjxAwXiWtFCfQ7A.woff2) format('woff2')
//   // src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjxAwXiWtFCfQ7A.woff2) format('woff2');
//   src: local("Lato Regular"), local("Lato-Regular"), url(~static/fonts/S6uyw4BMUTPHjxAwXiWtFCfQ7A.woff2) format("woff2");
//   unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
// /* latin */
// @font-face {
//   font-family: "Lato";
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   // src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wXiWtFCc.woff2) format('woff2');
//   src: local("Lato Regular"), local("Lato-Regular"), url(~static/fonts/S6uyw4BMUTPHjx4wXiWtFCc.woff2) format("woff2");
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
//     U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }



@mixin font-mixin {
  @include fontFamily;
  font-style: normal;
  // font-weight: 300 !important;
  // line-height: 1.5;
  color: $font_color;
}

:root {
  font-size: 90%;
}

body {
  cursor: auto;
  @include font-mixin;
  margin: 0;
  padding: 0;
  position: relative;
  background-color: $background_color;
  font-size: $font-size-base;
}
// .list-group-item {
//   // background-color: $background_color_shade1;
// }

p {
  // font-style: italic;
  // color: #CCC;
}

a {
  color: inherit;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

// .card {
//   background-color: $background_color_shade1;

//   .card-header {
//     background-color: $background_color_shade1;
//   }
// }

.capitalize {
  // &::first-letter {
    text-transform: capitalize;
  // }
}

.table {

  text-align: left;

  tr {
    th {
      @include font-mixin;
    }
    td {
      @include font-mixin;
    }
  }
}

.input-group {
  padding: 5px 5px;

  &:last-of-type {
    padding-bottom: 10px;
  }
}


.pad10 {
  padding: 10px 0;
}

.flex {
  display: flex;
  text-align: center;
  justify-content: center;
}


.label {
  width: 200px;
  color: $blue_h8;
}

.content {
  font-weight: bold;
  color: $blue_h8;
}

h1,
h2,
h3,
h4 {
  @include fontFamily;
  font-style: normal;
  // font-weight: 200;
  line-height: 1.5;
  // padding-bottom: 10px;
}


h1 {
  font-size: 1.5rem;
  border-bottom: 1px $blue_h8 solid;
  text-align: center;
  padding: 10px;
  color: $blue_h8;
}

h2 {
  font-size: 1.4rem;
}

h3 {
  font-size: 1.3rem;
}

h4 {
  font-size: 1.2rem;
}

.cursor-pointer {
  cursor: pointer;
}

.height-100p {
  height: 100% !important;
}

// .main-container {
//   // padding-top: 20px;
// }

// $screen-sm-min : 500px;

button {
  @extend .btn;
  @extend .btn-dark;
}

.app-container {
  .logo {
    display: flex;
    position: relative;
    padding-top: 25px;
    padding-left: 100px;
    // padding-left: 90px;
    margin-bottom: 60px; // because of the menu
    z-index: 1000;
    // justify-content: center;
    // align-items: center;

    h1 {
      @extend .d-none;
      @extend .d-sm-block;
      padding-top: 40px;
      padding-left: 70px;
    }

    img {
      width: 180px;
    }
  }
  .app-content {
    min-height: 100vh;
    min-width: 100vw;
    padding-bottom: 80px;

  }
}

.public-input {
  border: 1px solid $blue_h8;
  width: 100%;
  margin: 0px;
  padding: 5px;
}

.backoffice-container {
  padding-top: 0px;

  .breadcrumb {
    margin-bottom: 30px;
  }
}



.app-body {
  padding-top: 15px;
}


// .modal {
//   padding: 0 !important; // override inline padding-right added from js
// }
// .modal .modal-dialog {
//   width: 100%;
//   max-width: none;
//   height: 100%;
//   margin: 0;
// }
// .modal .modal-content {
//   height: 100%;
//   border: 0;
//   border-radius: 0;
// }
// .modal .modal-body {
//   overflow-y: auto;
// }