@import "static/css/collapse.scss";

.room-type-container {
  .card-header {
    cursor: pointer;
  }

  @include  collapse(1500px, "-room");

  .actions {
    button {
      margin-right: 5px;
    }
  }

}