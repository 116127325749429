.search-container {
  .search-form {
    padding-bottom: 30px;
  }

  .search-result {

    span {
      margin-right: 5px;
      // text-transform: capitalize;
    }
  }
}