.btn-white {

  border-width: 0.25;
  color: #00356b;

  &:hover {
    background-color: #00356b ;
    color: white;
  }

  &:focus {
    background-color: white ;
    color: black;
  }
}