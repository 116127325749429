.clic2call-container {
  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    position: fixed;
    padding-bottom: 0px;
    z-index: 3000;
    bottom: 50px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    position: fixed;
    padding-bottom: 0px;
    z-index: 3000;
    bottom: 50px;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    position: fixed;
    padding-bottom: 0px;
    z-index: 3000;
    bottom: 50px;
  }

  position: absolute;
  bottom: 30px;
  right: 50px;
  padding-bottom: 25px;

  .clic2call-position {
    position: absolute;
    bottom: 90px;
    right: 0;

    .clic2call-close {
      position: absolute;
      top: 5px;
      right: 5px;
      z-index: 10;

      button {
        background-color: inherit;
        border: inherit;
      }

      span {
        color: white;
        font-size: 24px;
      }
    }

    .clic2call-content {
      position: relative;
      min-height: 300px;
      width: 250px;
      // background-color: darkred;
      background-color: #00356b;
      color: white;
      padding: 15px;
      border-radius: 5%;

      button {
        margin-top: 10px;
      }
    }
  }
  .clic2call-handle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // background-color: rgba($color: #000000, $alpha: 0.5);
    background-color: #00356b;
    border-radius: 35px;
    color: white;

    .handle-label {
      padding: 15px;

      button {
        background-color: inherit;
        border: inherit;
        box-shadow: none;
      }

      .icon-phone {
        margin-left: 10px;
      }
    }

    .handle {
      background-color: #00356b;
      color: white;
      padding: 25px;
      border-radius: 50%;
      // font-size: 50px;
    }
  }
}
