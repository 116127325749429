.proposition-row-display-container {
  .carousel-control-next {
    opacity: 1 !important;
    background: rgba(255, 255, 255, 0.2);
  }
  .carousel-control-prev {
    opacity: 1 !important;
    background: rgba(255, 255, 255, 0.2);
  }
}
