@mixin collapse($minHeight, $name) {
  .collapse-container#{$name} {
    transition: max-height 400ms ease;
    max-height: 0px;
    overflow: hidden;

    &.collapse-visible#{$name} {
      visibility: visible;
      max-height: $minHeight;
      overflow: visible!important;

    }
  }
}
