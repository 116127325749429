
@import "static/css/collapse.scss";

.customer-details-container {

  .details {
    padding-top: 15px
  }

  @include  collapse(250px, "-address");


  // .details-info {
  //   padding-bottom: 20px;
  // }
}
