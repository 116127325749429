$banner_width: 360px;
$item_margin: 25px;
$break-large: 1024px;

.notification-container {
  h2 {
    padding-top: 10px;
  }

  h3 {
    font-size: 16px;
  }

  ul {
    padding-top: 15px;
    cursor: default;

    li {
      list-style: none;
      padding: 3px;
    }

    span {
      cursor: pointer;
    }
  }

  .menu-button {
    position: absolute;
    top: 20px;
    left: 30px;
    // color: #373a47;
    color: white;
    font-size: 42px;
    cursor: pointer;
    z-index: 1500;
  }

  .banner {
    position: fixed;
    margin: 0;
    top: 0;
    right: -$banner_width;
    height: 100%;
    width: $banner_width;
    background-color: white;
    // background-color: #373a47;
    color: white;
    border: 2px #CCC solid;
    transition: right 300ms ease-in-out;
    overflow: hidden;
    text-align: left;
    z-index: 900;
    padding: 20px 10px 10px 10px;
    // padding: 100px 0 0 80px;
    font-size: 1rem;

    .close {
      position: absolute  ;
      top: 10px;
      right: 10px;
    }

    .content {
      overflow: scroll;
      height: 100vh;
      padding-bottom: 80px;

      .footer-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 80px;
        width: 100%;
        background-color: white;
        z-index: 5000;

        .footer {
          // position: fixed;
          bottom: 0;
          padding: 15px;
          font-size: 15px;
        }
      }
    }

    &.visible {
      right: 0px;

      // @media screen and (max-width: $break-large) {
      //   background-color: rgba(0, 0, 0, 1);
      // }

      // @media screen and (max-width: $banner_width) {
      //   width: 100%;
      //   background-color: rgba(0, 0, 0, 1);
      // }
    }
  }

  .list {
    padding-top: 10px;
    width: 100%;
    height: 100%;
  }
}
