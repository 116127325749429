.content-modal-test {
  h1 {
    color: var(--theme-color);
    border-bottom: 0;
    text-transform: uppercase;
    font-family: "Playfair";
    font-size: 1.6rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: var(--theme-color);
    background-color: var(--theme-color);
  }

  .btn {
    color: #fff;
    background-color: var(--theme-color)!important;
    border-color: var(--theme-color)!important;
  }

  .form-control {
    border: 1px solid var(--theme-color);
  }

  .input-group-text {
    background-color: var(--theme-color);
    border: 1px solid var(--theme-color);
    color: white;
  }
}


.pre-stay-container {
  text-align: center;

  padding: 5px;

  h1 {
    color: var(--theme-color);
    border-bottom: 0;
    text-transform: uppercase;
    font-family: "Playfair";
    font-size: 1.6rem;
  }

  h2 {
    font-size: 1.2rem;
    font-family: "Playfair";
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: var(--theme-color);
    background-color: var(--theme-color);
  }

  .btn {
    color: #fff;
    background-color: var(--theme-color)!important;
    border-color: var(--theme-color)!important;
  }

  .form-control {
    border: 1px solid var(--theme-color);
  }

  .input-group-text {
    background-color: var(--theme-color);
    border: 1px solid var(--theme-color);
    color: white;
  }

  .horizontal-separator {
    border-right: 2px var(--theme-color) solid;
    padding-right: 70px !important;
  }

  .title-map-container {
    // .focused {
    //   // border: 5px red solid;
    // }

    .title {
      transition: ease all 500ms;
      transition-duration:0.5s;
      padding-top: 15px;

      h3 {
        transition: ease all 500ms;
        padding: 0;
        margin: 0;
        font-size: 1.8rem;
        text-align: left;
        color: var(--theme-color);
        font-family: "CaslonGrad";
      }

      h2 {
        transition: ease all 500ms;
        padding: 0;
        margin: 0;
        font-size: 1.0rem;
        text-align: left;
        color: var(--theme-color);
        font-family: "CaslonGrad";
      }

      &.focused {
        h3 {
          transform-origin: top left;
          transform: scale(1.4, 1.4) translateY(-5px);
          // font-size: 4rem;
          font-weight: bold;
        }

        h2 {
          transform-origin: top left;
          transform: scale(1.4, 1.4);
          font-weight: bold;
          // font-size: 1.8rem;
        }
      }
    }
  }
}
