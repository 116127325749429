@import "static/css/collapse.scss";

.reservation-details-container {
  @include collapse(1500px, "-reservation-details");

  .card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}